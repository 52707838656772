<template lang="pug">
    p(v-bind="attributes", v-html="content")
</template>

<script>
import { props } from '@/mixins/component'

export default {
    name: 'component-paragrafo',
    props,
    computed: {
        content() {
            return this.contents.content
        },
		attributes() {
			return this.contents.attributes
		}
    },
}
</script>

<style lang="stylus" scoped src="./Paragrafo.styl"></style>
