import { render, staticRenderFns } from "./Paragrafo.vue?vue&type=template&id=7f65f8fe&scoped=true&lang=pug&"
import script from "./Paragrafo.vue?vue&type=script&lang=js&"
export * from "./Paragrafo.vue?vue&type=script&lang=js&"
import style0 from "./Paragrafo.styl?vue&type=style&index=0&id=7f65f8fe&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f65f8fe",
  null
  
)

export default component.exports